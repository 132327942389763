import React, { useState, useEffect } from "react";
import axios from "axios";
// import io from "socket.io-client";
import { app, auth, googleProvider } from "../config/firebase-config";
import { onAuthStateChanged, signInWithPopup, signInWithRedirect } from "firebase/auth";
import { useLocation } from "react-router-dom";
import "../styles/Home.css";
import "./arrows.svg";
import { set } from "mongoose";
// import Masonry from "masonry-layout";

// const ENDPOINT = `${process.env.REACT_APP_BASE_URL}`;

// const socket = io(ENDPOINT);
 
function Home() {
  const [posts, setPosts] = useState([]);
  const [newPost, setNewPost] = useState("");
  const [user, setUser] = useState(null);
  const [isUpvoted, setIsUpvoted] = useState(false);
  const [localUpvotes, setLocalUpvotes] = useState({});
  const location = useLocation();
  const initialUpvotes = {};
  const [localPost, setLocalPost] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState(null);
  const [selectedTags, setSelectedTags] = useState(""); //for the post window
  const [filterTags, setFilterTags] = useState([]); //for filtering posts
  const [searchTerm, setSearchTerm] = useState("");
  const [charCount, setCharCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [currentEmoji, setCurrentEmoji] = useState(0);
  // const emojis = ['💡', '🎨', '👨🏻‍🤝‍👨🏻', '💻', '🌎', '💼', '💩'];
  const emojis = ['💡', '🎨', '🔬', '💻', '📚', '🎵', '🌎', '💼', '🍎', '🏋️', '💩', '🚀', '🎥', '🎮', '🧪', '🌱', '💰'];
  const [sortOrder, setSortOrder] = useState();

  const fetchPosts = async () => {
    try{
    const res = await fetch(`${process.env.REACT_APP_BASE_URL}/api/posts`);
    const data = await res.json();
    setPosts(data);
    setData(data);
    setIsLoading(false); 
    // console.log('This are all the posts:', data)
    data.forEach(post =>{
      // console.log('This is the post data:', post)
      initialUpvotes[post._id] = post.upvotes;
    })
    // console.log('This is a post:', data)
    
    setLocalUpvotes(initialUpvotes);
    // console.log('This is the localUpvotes data:', localUpvotes)
    // console.log('Posts:', data);
  } catch(error) {
    console.log('Failed to fetch posts');
    setIsLoading(true);
  }
};

const fetchUser = async(user) => {
  const uid = user.uid;
  // console.log('The user:', user)
  try{
    const res = await fetch(`${process.env.REACT_APP_BASE_URL}/api/${uid}/user`);
    const data = await res.json();
    // console.log('Upvoted posts', data)
    console.log('Upvoted posts:', data);
    // setPosts((prevPosts) => [data.upvotedPosts, ...prevPosts]);
  } catch(error) {
    console.log('Failed to fetch user');
  }
}

  useEffect(() => {
    let interval;
    // console.log("We are in Home.js");
    // console.log('The Selected Tags are:',selectedTags)
    // console.log('The error is:', localStorage.getItem('lastError'))
    // console.log('The server response is:', localStorage.getItem('serverRes'))
    // console.log('Loading:', isLoading)
    // console.log('Posts Length:', posts.length);
    // console.log('Posts:', posts) 

  // console.log('Asking to fetch posts')

  fetchPosts();

  // const intervalId = setInterval(fetchPosts, 1000); // 5000 milliseconds = 5 seconds

    if (isLoading) {
      // console.log('Loading:', isLoading)
      interval = setInterval(() => {
        setCurrentEmoji(currentEmoji => (currentEmoji + 1) % emojis.length);
        // console.log('Current Emoji:', currentEmoji);
      }, 1000); // Change emoji every 1000ms (1 second)
      fetchPosts();
    }

    //create a loading animation while the posts are being fetched
    // if (!data) {
    //   return <div>Ideas Loading...</div>;
    // }

    // console.log("Local UID", localStorage.getItem("userId"));

    const fetchUpvotedPosts = async () => {
      try {
        // console.log('Local UID', localStorage.getItem('userId'))
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/upvote?uid=${localStorage.getItem('userId')}`);
        const data = await response.json();

        // Assuming the backend returns an array of post IDs
        setUpvotedPosts(data);
        // console.log('Upvoted posts:', data);
        // Save the upvoted posts to localStorage
        localStorage.setItem('upvotedPosts', JSON.stringify(data));
      } catch (error) {
        console.error('Failed to fetch upvoted posts:', error);
      }
    };

    // axios.get(`${process.env.REACT_APP_BASE_URL}/api/posts`)
    //   .then(res => setPosts(res.data))
    //   .catch(err => console.log(err));

    // const socket = socketIOClient(ENDPOINT);

    // socket.on('new post', (newPost) => {
    //   setPosts(prevPosts => [newPost, ...prevPosts]);
    // });

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      // console.log("User:", user);
      // fetchUpvotedPosts();
      // localStorage.setItem("userId", user.uid);

      // If the user is null (i.e., the user has signed out), reset the upvotes
      if (!user) {
        // setLocalUpvotes({});
        localStorage.removeItem("upvotedPosts");
        // localStorage.removeItem("userId");
        // localStorage.removeItem("token");
      }
    });

    return () => {
      // socket.disconnect();
      // socket.off('new post');
      unsubscribe();
      clearInterval(interval);
      // clearInterval(intervalId);
    };
  }, [isLoading]);
  

  const sendTokenToServer = async (user) => {
    const idToken = await user.getIdToken(true);

    fetch(`${process.env.REACT_APP_BASE_URL}/auth/firebase`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ idToken }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        // console.log("Server response:", data); //uncomment if you want to see the data being sent from the backend
        localStorage.setItem("token", data.token);
        localStorage.setItem("userId", data.userId);
      })
      .catch((error) => {
        console.error("Error sending ID token to server:", error);
      });
  };

  const upvotePost = async (post) => {
    console.log("we upvoting");
    // console.log(post._id);
    const id = post._id;
    const userId = localStorage.getItem("userId");

    // console.log('The token', localStorage.getItem('token'))
    fetch(`${process.env.REACT_APP_BASE_URL}/posts/${id}/upvote`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({ userId }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        // console.log("The response:", data);
        // Handle the server response as needed
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    // const updatedPost = res.data.post;
    // update your posts state here with the updatedPost data
  };

  const downvotePost = async (post) => {
    console.log("we undoing upvote");
    // console.log(post._id);
    const id = post._id;
    const userId = localStorage.getItem("userId");

    // console.log('The token', localStorage.getItem('token'))
    fetch(`${process.env.REACT_APP_BASE_URL}/posts/${id}/downvote`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({ userId }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        // console.log("The response:", data);
        // Handle the server response as needed
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    // const updatedPost = res.data.post;
    // update your posts state here with the updatedPost data
  };

  const [upvotedPosts, setUpvotedPosts] = useState(() => {
    // Get the upvoted posts from localStorage when the component is mounted
    const savedUpvotes = localStorage.getItem('upvotedPosts');
    return savedUpvotes ? JSON.parse(savedUpvotes) : {};
  });

  const handleUpvote = (post) => {
    if (!user) {
      signInWithPopup(auth, googleProvider)
        .then((result) => {
          // console.log(result.user);
          result.user.getIdToken().then((idToken) => {
            setUser(result.user);
            sendTokenToServer(result.user);
          });
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      const isUpvoted = upvotedPosts[post._id];
      if (isUpvoted) {
        downvotePost(post); // function to downvote the post
        setLocalUpvotes({
          ...localUpvotes,
          [post._id]: (localUpvotes[post._id] || 0) - 1,
        });
      } else {
        upvotePost(post); // function to upvote the post
        setLocalUpvotes({
          ...localUpvotes,
          [post._id]: (localUpvotes[post._id] || 0) + 1,
        });
      }
      const newUpvotedPosts = {
        ...upvotedPosts,
        [post._id]: !isUpvoted,
      };
      setUpvotedPosts(newUpvotedPosts);

      // Save the upvoted posts to localStorage whenever they change
      localStorage.setItem("upvotedPosts", JSON.stringify(newUpvotedPosts));
    }
  };

  const createPost = async () => {
    // console.log(user)
    setIsModalOpen(false);

    if (!user) {
      try {
        const result = await signInWithPopup(auth, googleProvider);
        const idToken = await result.user.getIdToken();
        setUser(result.user);
        sendTokenToServer(result.user);
      } catch (error) {
        console.error(error);
        localStorage.setItem('lastError', JSON.stringify(error));
      }
    }

      console.log("User signed in");
      // const userId = user.uid;

      // console.log(userId);

      // axios
      // .post(`${process.env.REACT_APP_BASE_URL}/api/posts`, {
      //   content: newPost,
      //   authorId: userId,
      // })
      // .then((res) => {
      //   const socket = socketIOClient(ENDPOINT);
      //   socket.emit("postCreated", res.data);
      //   setNewPost("");
      // })
      // .catch((err) => console.log(err));

      //sending to the database
      const url = `${process.env.REACT_APP_BASE_URL}/api/posts`;
      const data = {
        content: newPost,
        authorId: localStorage.getItem("userId"),
        tags: selectedTags,
      };
      // console.log("The data:", data);
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // "Authorization": `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(data),
        // body: JSON.stringify({ content: 'Your post content', authorId: localStorage.getItem('userId') }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          // console.log("Server response:", data);
          
          // Handle the server response as needed
          setPosts((prevPosts) => [data, ...prevPosts]);
          setNewPost("");
          setSelectedTags("");
          setCharCount(0);
        })
        .catch((error) => {
          console.error("Error:", error);
          localStorage.setItem('lastError', JSON.stringify(error));
        });

      // //showing it on the frontend
      // localPost.content = newPost;
      // const post = {
      //   content: newPost,
      // };

      // // Add new post to local state
      // setPosts((prevPosts) => [post, ...prevPosts]);
      // setLocalUpvotes((prevUpvotes) => ({ ...prevUpvotes, [post._id]: 0 }));

      // // Clear the new post input
      // setNewPost("");


    // socket.emit('new post');
  };

  const tags = ['Tech', 'Social', 'Business', 'Fantasy', 'Society', 'Art', 'Shitpost']; // replace with your array of tags

  function handleTagClick(tag) {
    if (selectedTags.includes(tag)) {
      // If the tag is already selected, remove it from the array
      setSelectedTags(selectedTags.filter(t => t !== tag));
    } else {
      // If the tag is not selected, add it to the array
      setSelectedTags([...selectedTags, tag]);
    }
  }

  const handleSortOrderChange = (event) => {
    setSortOrder(event.target.value);
  };
  
  return (
    <div className="Home">
      {/* <input value={newPost} onChange={(e) => setNewPost(e.target.value)} />
      <button onClick={createPost}>Create Post</button> */}
      <div className="tag-filter">
        <div className="search-container">
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="search-bar"
          />
          <svg
            className="search-icon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            fill="black"
            width={15}
          >
            <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" />
          </svg>
        </div>
        <div className="filter-container">
          {tags.map((tag) => (
            <button
              key={tag}
              className={filterTags.includes(tag) ? "selected" : ""}
              onClick={() => {
                if (filterTags.includes(tag)) {
                  setFilterTags(filterTags.filter((t) => t !== tag));
                } else {
                  setFilterTags([...filterTags, tag]);
                }
                console.log("The filter tags are:", filterTags);
              }}
              id="filter"
            >
              {tag}
            </button>
          ))}
        </div>
        <div className="sort-posts">
        <select value={sortOrder} onChange={handleSortOrderChange}>
            <option value="New">New ✨</option>
            <option value="Top">Top 🔥</option>
            <option value="Shuffle">Shuffle 🔀</option>
            <option value="Upvoted">Upvoted 🧡</option>
          </select>
        </div>
      </div>
      {isLoading === true ? (
        <div className="loading-container">
          <div className="loading">{emojis[currentEmoji]}</div>
        </div>
      ) : (
        <div className="posts-container">
          <div className="all-posts">
            {posts
              .filter((post) =>
                filterTags.every((tag) => post.tags.includes(tag))
              )
              .filter((post) =>
                post.content.toLowerCase().includes(searchTerm.toLowerCase())
              )
              .sort((a, b) => {
                // console.log(a.upvotes, b.upvotes)
                switch (sortOrder) {
                  case 'Top':
                    // Sort by upvotes in descending order
                    return b.upvotes - a.upvotes;
                  case 'Shuffle':
                    // Sort by date in descending order
                    return Math.random() - 0.5;
                  case 'Upvoted':
                    fetchUser(user);
                    return 0;
                  default:
                    // If no sort order is selected, don't sort the posts
                    return 0;
                }
              })
              .map((post) => {
                // const [isUpvoted, setIsUpvoted] = useState(false);
                return (
                  <div className="post" key={post._id}>
                    {/* <h2>{post.title}</h2>
                    console.log(user.uid)*/}
                    <div id="post-text">"{post.content}"</div>
                    <br></br>
                    <div className="post-footer">
                      <div className="post-tag">
                        {post.tags.map((tag, index) => (
                          <div key={index} className="tag" id={tag}>
                            {tag}
                          </div>
                        ))}
                      </div>
                      <div className="upvote-container">
                        <div className="upvote-arrow">
                          <button id="arrow" onClick={() => handleUpvote(post)}>
                            {upvotedPosts[post._id] ? (
                              <svg
                                version="1.1"
                                id="Capa_1"
                                xmlns="http://www.w3.org/2000/svg"
                                x="0px"
                                y="0px"
                                width="30px"
                                height="30px"
                                viewBox="0 0 576.556 576.557"
                                fill="red"
                              >
                                <path
                                  d="M25.812,362.18c9.663,6.625,21.026,10.127,32.859,10.127h52.383v141.798c0,32.058,26.082,58.14,58.14,58.14h238.167
    c32.058,0,58.14-26.082,58.14-58.14V372.307h52.384c11.833,0,23.195-3.502,32.858-10.127
    c8.913-6.109,15.942-14.595,20.331-24.536c4.388-9.943,5.919-20.854,4.427-31.557c-1.617-11.604-6.688-22.359-14.662-31.102
    L331.232,23.271c-10.99-12.048-26.646-18.958-42.954-18.958c-16.308,0-31.964,6.91-42.955,18.959L15.717,274.985
    c-7.975,8.742-13.045,19.497-14.662,31.102c-1.492,10.702,0.039,21.614,4.427,31.556C9.87,347.585,16.9,356.07,25.812,362.18z"
                                />
                              </svg>
                            ) : (
                              <svg
                                version="1.1"
                                id="Capa_1"
                                xmlns="http://www.w3.org/2000/svg"
                                x="0px"
                                y="0px"
                                width="30px"
                                height="30px"
                                viewBox="0 0 576.556 576.557"
                                fill="black"
                              >
                                <path
                                  d="M25.812,362.18c9.663,6.625,21.026,10.127,32.859,10.127h52.383v141.798c0,32.058,26.082,58.14,58.14,58.14h238.167
				c32.058,0,58.14-26.082,58.14-58.14V372.307h52.384c11.833,0,23.195-3.502,32.858-10.127
				c8.913-6.109,15.942-14.595,20.331-24.536c4.388-9.943,5.919-20.854,4.427-31.557c-1.617-11.604-6.688-22.359-14.662-31.102
				L331.232,23.271c-10.99-12.048-26.646-18.958-42.954-18.958c-16.308,0-31.964,6.91-42.955,18.959L15.717,274.985
				c-7.975,8.742-13.045,19.497-14.662,31.102c-1.492,10.702,0.039,21.614,4.427,31.556C9.87,347.585,16.9,356.07,25.812,362.18z
				 M47.368,303.855L276.975,52.142c3.034-3.326,7.169-4.989,11.304-4.989c4.134,0,8.269,1.663,11.303,4.989l229.607,251.714
				c8.959,9.822,1.99,25.611-11.304,25.611h-79.924c-8.45,0-15.3,6.851-15.3,15.3v169.338c0,8.449-6.851,15.3-15.3,15.3H169.195
				c-8.45,0-15.3-6.851-15.3-15.3V344.767c0-8.449-6.85-15.3-15.3-15.3H58.671C45.376,329.467,38.408,313.679,47.368,303.855z"
                                />
                                <path
                                  d="M407.362,572.744H169.195c-32.334,0-58.64-26.306-58.64-58.64V372.807H58.671c-11.935,0-23.395-3.532-33.142-10.215
				c-8.989-6.161-16.08-14.719-20.506-24.747c-4.426-10.027-5.969-21.033-4.464-31.827c1.631-11.704,6.745-22.552,14.788-31.369
				L244.954,22.935c11.085-12.152,26.876-19.122,43.324-19.122c16.448,0,32.238,6.97,43.323,19.122l229.607,251.714
				c8.043,8.817,13.157,19.665,14.788,31.369c1.505,10.793-0.039,21.799-4.465,31.828c-4.427,10.028-11.518,18.586-20.506,24.746
				c-9.746,6.683-21.206,10.215-33.141,10.215h-51.884v141.298C466.001,546.438,439.696,572.744,407.362,572.744z M288.278,4.812
				c-16.168,0-31.689,6.851-42.585,18.796L16.086,275.322C8.18,283.99,3.153,294.652,1.55,306.156
				c-1.479,10.609,0.039,21.427,4.389,31.284c4.351,9.858,11.321,18.271,20.156,24.327c9.58,6.567,20.844,10.039,32.576,10.039
				h52.883v142.298c0,31.782,25.857,57.64,57.64,57.64h238.167c31.782,0,57.64-25.857,57.64-57.64V371.807h52.884
				c11.731,0,22.996-3.472,32.575-10.039c8.835-6.056,15.805-14.468,20.157-24.326c4.35-9.858,5.867-20.677,4.389-31.285
				c-1.604-11.504-6.63-22.167-14.536-30.834L330.863,23.608C319.966,11.663,304.446,4.812,288.278,4.812z M407.362,529.904H169.195
				c-8.712,0-15.8-7.088-15.8-15.8V344.767c0-8.16-6.639-14.8-14.8-14.8H58.671c-6.352,0-11.89-3.609-14.455-9.42
				c-2.564-5.811-1.499-12.335,2.782-17.028L276.605,51.805c2.987-3.274,7.241-5.152,11.673-5.152c4.431,0,8.686,1.878,11.672,5.152
				l229.607,251.714c4.28,4.692,5.346,11.218,2.781,17.028s-8.104,9.42-14.454,9.42h-79.924c-8.161,0-14.8,6.64-14.8,14.8v169.338
				C423.162,522.816,416.074,529.904,407.362,529.904z M288.278,47.652c-4.151,0-8.137,1.759-10.934,4.826L47.737,304.192
				c-4.01,4.396-5.008,10.508-2.606,15.951c2.402,5.442,7.59,8.823,13.54,8.823h79.923c8.712,0,15.8,7.088,15.8,15.8v169.338
				c0,8.16,6.639,14.8,14.8,14.8h238.167c8.161,0,14.8-6.64,14.8-14.8V344.767c0-8.712,7.088-15.8,15.8-15.8h79.924
				c5.949,0,11.137-3.381,13.539-8.824c2.402-5.442,1.404-11.555-2.604-15.95L299.212,52.479
				C296.415,49.412,292.429,47.652,288.278,47.652z"
                                />
                              </svg>
                            )}
                          </button>
                        </div>
                        <div className="upvote-number">
                          <p id="upvote-no">{localUpvotes[post._id]}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
              }
          </div>

          <button
            className="new-post-button"
            onClick={() => setIsModalOpen(true)}
          >
            💭
          </button>
          {isModalOpen && (
            <div className="modal" onClick={() => setIsModalOpen(false)}>
              <div
                className="modal-content"
                onClick={(e) => e.stopPropagation()}
              >
                <div className="form">
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      position: "relative",
                    }}
                  >
                    <textarea
                      style={{ height: "250px", resize: "none" }}
                      placeholder="< your idea💡>"
                      maxLength={80}
                      value={newPost}
                      onChange={(e) => {
                        setNewPost(e.target.value);
                        setCharCount(e.target.value.length);
                      }}
                    />
                    <div
                      style={{
                        position: "absolute",
                        bottom: "40px",
                        right: "10px",
                        padding: "0 5px",
                        color: charCount === 80 ? "red" : "grey",
                      }}
                    >
                      {80 - charCount}
                    </div>
                  </div>
                  <div className="tag-buttons">
                    {tags.map((tag) => (
                      <button
                        key={tag}
                        type="button"
                        id={tag}
                        onClick={() => handleTagClick(tag)}
                        style={{
                          opacity: selectedTags.includes(tag) ? 1 : 0.3,
                        }}
                      >
                        {tag}
                      </button>
                    ))}
                  </div>
                  {/* <select onChange={(e) => setTag(e.target.value)}>
                  <option value="">Select tag</option>
                  <option value="tag1">Tag 1</option>
                  <option value="tag2">Tag 2</option>
                </select> */}
                  <button
                    id="post"
                    disabled={!newPost || !selectedTags}
                    style={{
                      opacity: !newPost || !selectedTags.length ? 0.5 : 1,
                      cursor:
                        newPost && selectedTags.length ? "pointer" : "default",
                    }}
                    onClick={() => createPost(newPost)}
                  >
                    POST
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      <footer>
        <div id="footer">
          Made with 💡 by
          <a
            href="https://www.aaryamanpatel.com/?utm_source=iwishtherewas.onrender.com&utm_medium=referral"
            target="_blank"
            rel="noopener noreferrer"
            style={{ marginLeft: "5px" }}
          >
            Aaryaman Patel
          </a>
        </div>
      </footer>
    </div>
  );
}

export default Home;