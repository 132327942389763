import React from 'react';
import { Link } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth, googleProvider } from '../config/firebase-config';
import { onAuthStateChanged, signInWithPopup } from "firebase/auth";
import '../styles/Navbar.css'
import logo from './logo.png'

const Navbar = ({ user }) => {
  
  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        // Sign-out successful
        window.location.href = '/'; // navigate to home page after logout
      })
      .catch((error) => {
        // An error happened
        console.error(error);
      });
  };

  const sendTokenToServer = async (user) => {
    const idToken = await user.getIdToken(true);

    fetch(`${process.env.REACT_APP_BASE_URL}/auth/firebase`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ idToken }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        // console.log("Server response:", data); //uncomment if you want to see the data being sent from the backend
        localStorage.setItem("token", data.token);
        localStorage.setItem("userId", data.userId);
      })
      .catch((error) => {
        console.error("Error sending ID token to server:", error);
      });
  };

  const handleLogin = () => {
    if(!user){
      signInWithPopup(auth, googleProvider)
      .then((result) => {
        console.log(result.user);
        result.user.getIdToken().then((idToken) => {
          // setUser(result.user);
          sendTokenToServer(result.user);
        });
      })
      .catch((error) => {
        console.error(error);
      });
    }

  };

  //     <div className="navbar">
  //       {user ? (
  //         <>
  //           <p>Hello, {user.displayName}!</p>
  //           <button onClick={handleLogout}>Logout</button>
  //         </>
  //       ) : (
  //         <p>Please sign in</p>
  //       )}
  //     </div>
  //   );
  return (
    <nav className='header'>
      <div></div>
      <Link to="/">
        <img src={logo} className='navbar-logo' alt='I Wish There Was...'/>
      </Link>
      {/* <h1><a className="fade-in" href="/">I Wish There Was...</a></h1> */}
      <div className='navbar fade-in'>
      {user ? (
      <div className="login-detail" style={{ textAlign: 'center' }}>
        <p id="name">Hey, {user.displayName}!</p>
        <button id="logout" onClick={handleLogout}>Logout</button>
      </div>
    ) : (
      <p className="login-detail" onClick={handleLogin}>Login</p>
      // <button onClick={handleLogout} style={{ cursor: 'pointer', marginTop: '5px' }}>Login</button>
    )}
      </div>
    </nav>
  );
}
export default Navbar;