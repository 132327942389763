// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, setPersistence, browserLocalPersistence } from 'firebase/auth';
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA2xmghuyV6ILiiaf88ZS5Qnbua2Pr4xBw",
  authDomain: "i-wish-there-was.firebaseapp.com",
  databaseURL: "https://i-wish-there-was-default-rtdb.firebaseio.com",
  projectId: "i-wish-there-was",
  storageBucket: "i-wish-there-was.appspot.com",
  messagingSenderId: "205429209850",
  appId: "1:205429209850:web:89fb3a7931c8154326e743",
  measurementId: "G-4ES3N7FXYH"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();

setPersistence(auth, browserLocalPersistence)
  .then(() => {
    console.log("Persistence set to 'local'");
  })
  .catch((error) => {
    console.error("Failed to set persistence:", error);
  });

export { app, auth, googleProvider };