import React, { useState, useEffect } from "react";
import {
  useHistory,
  Route,
  Routes,
  useNavigate,
  BrowserRouter as Router,
} from "react-router-dom";
import { app, auth, googleProvider } from "./config/firebase-config";
import { onAuthStateChanged, signOut } from "firebase/auth";
import Home from "./components/Home";
import Posts from "./components/Posts";
import Navbar from "./components/Navbar";

function App() {
  const [user, setUser] = useState(null);
  // const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in
        setUser(user);
      } else {
        // User is signed out
        setUser(null);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  return (
    <Router>
      <div className="App">
        <Navbar user={user} />
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
